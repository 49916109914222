

<script>
import APP_CONST from '@/constants/AppConst';
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  name: 'LineComponent',
  props: ['data', 'printChart', 'maxYCoordinate', 'yScaleLabel', 'isTooltipInPercent', 'hideAnnotation', 'isLabelOffSet', 'isCount', 'isQualityGraph'],
  watch: {
    data(newData) {
      this.renderChartData();
    }
  },
  mounted() {
    this.renderChartData();
  },
  computed: {
    chartData() {
      const gridLineColor = [];
      gridLineColor.push(APP_CONST.STYLE.COLOR.NEUTRAL_500);
      const chartData = {
        labels: [],
        datasets: [
        ],

      };
      chartData.labels = this.data && this.data.labels
        ? this.data.labels
        : [];
      if (this.data && this.data.datasets) {
        for (let i = 0; i < this.data.datasets.length; i++) {
          chartData.datasets.push(this.data.datasets[i]);
        }
      }

      return chartData;
    },
    options() {
      const printChartQuality = this.printChart;
      const isQualityChart = this.isQualityGraph;
      const options = {
        layout: {
          padding: {
            bottom: 12,
          }
        },
        tooltips: {
          enabled: this.printChart
            ? false
            : true,
          mode: 'single',
          displayColors: false
          , callbacks: {
            label: ((tooltipItems, data) => {
              if (tooltipItems.yLabel) {
                tooltipItems.yLabel = Math.round(tooltipItems.yLabel);
              }
              return !this.isTooltipInPercent
                ? this.isCount
                  ? data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].activeScholar + '/' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].participatingScholar
                  : this.isQualityGraph
                    ? Number(tooltipItems.value).toFixed(1)
                    : tooltipItems.yLabel + ''
                : tooltipItems.yLabel + '%';
            }),
            title: function (tooltipItem, data) {
              return;
            }
          }
        },
        animation: {
          duration: 1,
          onComplete: function () {
            if (printChartQuality) {
              const scale = window.devicePixelRatio;
              const sourceCanvas = this.chart.chart.canvas;
              const copyWidth = this.chart.scales['y-axis-0'].width - 8;
              const copyHeight = this.chart.scales['y-axis-0'].height + this.chart.scales['y-axis-0'].top + 5;
              const targetCtx = document.getElementById('myChartAxisPdf').getContext('2d');
              targetCtx.scale(scale, scale);
              targetCtx.canvas.width = copyWidth * scale;
              targetCtx.canvas.height = copyHeight * scale;
              targetCtx.canvas.style.width = `${copyWidth}px`;
              targetCtx.canvas.style.height = `${copyHeight}px`;
              targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth * scale, copyHeight * scale, 0, 0, copyWidth * scale, copyHeight * scale);
              const sourceCtx = sourceCanvas.getContext('2d');
              sourceCtx.clearRect(0, 0, copyWidth, copyHeight);
              const ctx = this.chart.ctx;
              ctx.fillStyle = 'rgb(0, 0, 0)';
              const fontSize = APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_400_HEAVY_SIZE;
              const fontStyle = APP_CONST.STYLE.FONT_SIZE.PARAGRAPH_400_HEAVY_WEIGHT;
              const fontFamily = APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT;
              ctx.font = Chart.helpers.fontString(
                fontSize,
                fontStyle,
                fontFamily
              );
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              this.data.datasets.forEach(function (dataset) {
                for (let i = 0; i < dataset.data.length; i++) {
                  if (!dataset.data[i].fakeValue) {
                    const model =
                      dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                      scale_max =
                        dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale
                          .maxHeight;
                    ctx.fillStyle = APP_CONST.STYLE.COLOR.NEUTRAL_700;
                    let y_pos = model.y + 1;
                    if ((scale_max - model.y) / scale_max >= 0.93) {
                      if (dataset.data[i] > 80) {
                        y_pos = model.y + 25;
                      }
                      else {
                        y_pos = model.y + 25;
                      }
                    }
                    ctx.fillText(Number(dataset.data[i].y).toFixed(1), model.x + 10.0, y_pos);
                  }
                }
              });
            }
          },
        },


        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                zeroLineWidth: 1,
                zeroLineColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                drawTicks: false,
              },
              ticks: {
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: this.printChart
                  ? 12
                  : 12,
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                padding: 10,
                display: true,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                zeroLineWidth: 1,
                zeroLineColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
              },
              ticks: {
                stepSize: !this.isQualityGraph
                  ? this.maxYCoordinate
                    ? (this.maxYCoordinate / 10)
                    : 10
                  : 1,
                max: !this.isQualityGraph
                  ? this.maxYCoordinate
                    ? this.maxYCoordinate
                    : 100
                  : 4,
                min: 0,
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: this.printChart
                  ? 16
                  : 12,
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_700,
                fontWeight: 'normal',
              },
              scaleLabel: {
                display: true,
                labelString: this.yScaleLabel,
                fontSize: this.printChart
                  ? 20
                  : 16,
                fontStyle: 'bold',
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
              },
            },
          ],
        },
        responsive: true,
        spanGaps: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        annotation: {
          drawTime: 'beforeDatasetsDraw',
          annotations: [{
            id: 'hline',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: this.isQualityGraph
              ? 3
              : 80,
            borderColor: APP_CONST.STYLE.COLOR.NEUTRAL_500,
            borderWidth: 0,
            borderDash: [7, 7],
            borderDashOffset: 5,
          }]
        }
      };
      if (this.hideAnnotation) {
        options.annotation = null;
      }
      return options;
    },
  },
  methods: {
    renderChartData() {
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>